<template>
  <div class="recorded-info" v-if="courseInfo">
    <div class="banner">
      <div class="banner-content">
        <div class="banner-info">
          <div class="banner-title">{{ courseInfo.name }}</div>
          <div class="banner-tip">
            {{ courseInfo.chapter_count }}章{{ courseInfo.section_count }}节，{{
              courseInfo.duration
            }}课时 <span class="line">|</span>
            <span v-for="(name, index) in courseInfo.teachers" :key="index"
              >{{ name.name }}
            </span>
          </div>
          <div class="banner-describe">{{ courseInfo.intro }}</div>
          <div class="grade">
            <Star :type="'show'" :scoreNum="courseInfo.evaluation_avg_score" />
            <span class="grade-statistic"
              >{{ courseInfo.evaluation_avg_score }}分 已有{{
                courseInfo.evaluation_count
              }}人评价</span
            >
          </div>
          <div
            class="marks"
            v-if="courseInfo.course_tags && courseInfo.course_tags.length > 0"
          >
            <div class="mark" v-for="tag in courseInfo.course_tags" :key="tag">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recorded-content clearfix">
      <div class="tab-box">
        <div class="tab-titles">
          <div
            class="tab-title"
            :class="tabIndex == 2 ? 'active' : ''"
            @click="tabOpe(2)"
          >
            合辑<span class="try">试看</span>
          </div>
          <div
            class="tab-title"
            :class="tabIndex == 1 ? 'active' : ''"
            @click="tabOpe(1)"
          >
            介绍
          </div>
          
          <div
            class="tab-title"
            :class="tabIndex == 3 ? 'active' : ''"
            @click="tabOpe(3)"
          >
            评价（{{
              !evaluation || evaluation.data.length == 0
                ? 0
                : evaluation.data.length
            }}）
          </div>
        </div>
        <div class="tab-content" v-show="tabIndex == 1">
          <div v-html="courseInfo.desc"></div>
        </div>
        <div class="tab-content" v-show="tabIndex == 2">
          <ul class="directory" v-for="item in chapterSection" :key="item.id">
            <li
              class="chapter"
              @click="$router.push('/recordedInfo/' + item.id)"
            >
              <img :src="item.image" class="chapter-img" />
              <div class="chapter-content">
                <p class="chapter-name">{{ item.name }}</p>
                <p class="teacher">
                  <span v-for="teacher in item.teachers" :key="teacher.id">{{
                    teacher.name
                  }}</span>
                </p>
              </div>
              <div class="chapter-price"  v-if="websiteData&&websiteData.is_course_pay==1">
                <p class="price">￥{{ item.price }}</p>
                <p class="original-price">￥{{ item.original_price }}</p>
              </div>
            </li>
          </ul>
        </div>
        <ul class="evaluate-list" v-show="tabIndex == 3">
          <li
            class="evaluate-info"
            v-for="item in evaluation.data"
            :key="item.id"
          >
            <img
              class="user-img"
              :src="
                item.avatar || require('@/assets/head-default-personal.png')
              "
            />
            <div class="evaluate-content">
              <div class="evaluate-title">
                {{ item.name
                }}<span class="date">发布于{{ item.created_at }}</span>
              </div>
              <div class="grade">
                <Star :type="'show'" :scoreNum="item.score" />
                <span class="grade-statistic">{{ item.score }}分</span>
              </div>
              <div class="evaluate-text">{{ item.content }}</div>
            </div>
          </li>
          <li
            class="evaluate-info"
            v-if="!evaluation || evaluation.data.length == 0"
          >
            暂无评价
          </li>
        </ul>

        <div class="evaluate" v-show="tabIndex == 3 && (websiteData&& websiteData.is_comment==1)">
          <Star :type="'ope'" @returnNum="getScore" />
          <textarea
            class="evaluate-text"
            cols="30"
            rows="10"
            v-model="textarea"
          ></textarea>
          <div class="evaluate-ope">
            <label class="anonymity"
              ><input type="checkbox" v-model="anonymous" /> 是否匿名</label
            >
            <button class="submit-btn" @click="submitBtn">提交评价</button>
          </div>
        </div>
      </div>
      <div class="selling-info">
        <div class="selling-introduce">
          <div
            class="collection"
            :class="isCollect == 1 ? 'active' : ''"
            @click="collectBtn(courseInfo.id)"
          >
            {{ isCollect == 1 ? "已收藏" : "收藏" }}
          </div>
          <img class="selling-img" :src="courseInfo.image" alt="" />
          <div class="selling-content">
            <div class="price" v-if="websiteData&&websiteData.is_course_pay==1">
              <span class="current-price"
                ><span v-if="courseInfo.price != '0.00'">￥</span
                >{{
                  courseInfo.price == "0.00" ? "免费" : courseInfo.price
                }}</span
              >
              <!-- <span class="original-cost" v-if="courseInfo.original_price!='0.00'">￥{{courseInfo.original_price}}</span> -->
            </div>
            <!-- <div class="purchased">34564人已购</div> -->
            <div class="btns">
              <button
                class="pay btn goPay"
                @click="payNowBtn(courseInfo.id)"
                v-if="courseInfo.is_can_play == 0"
              >
                立即购买
              </button>
              <!-- <router-link
                class="pay btn"
                :class="{ test: courseInfo.is_can_play == 1 }"
                :to="'/coursePlay/' + courseInfo.id"
                v-if="courseInfo.is_can_play == 1"
                >点击观看</router-link
              > -->
              <router-link
                class="pay btn"
                :class="{ test: courseInfo.is_can_play == 1 }"
                :to="'/coursePlay/' + (firstCourseId || courseInfo.id)"
                v-if="courseInfo.is_can_play == 1 && ((tabIndex == 2 && chapterSection && chapterSection.length > 0) || tabIndex != 2)"
              >点击观看</router-link>
              <button
                class="test btn"
                @click="tryView"
                v-if="courseInfo.is_can_play == 0"
              >
                试听课程
              </button>
            </div>
          </div>
        </div>
        <div
          class="view-user"
          v-if="
            courseInfo.study_user_avatar &&
            courseInfo.study_user_avatar.length > 0
          "
        >
          <div class="view-user-title">观看用户</div>
          <div class="view-user-list">
            <img
              class="view-user-img"
              v-for="user in courseInfo.study_user_avatar"
              :key="user.id"
              :src="
                user.avatar || require('@/assets/head-default-personal.png')
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 视频弹窗 -->
    <div class="video-modal" v-show="videoModal">
      <div class="modal-content">
        <div class="modal-title">
          {{ courseInfo.name }}
          <div class="modal-close" @click="closeVideoModal"></div>
        </div>
        <div id="video-box"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Star from "@/components/pc/star"; //评分
import { mapMutations } from "vuex";
export default {
  name: "SeriesLessonsInfo",
  data() {
    return {
      videoModal: false,
      tabIndex: 2,
      loginTip: false, //登录提示
      courseInfo: "", //课程详情
      chapterSection: "", //课程章节
      firstCourseId: "", // 添加第一个课程ID
      evaluation: "", //评价
      isCollect: "", //是否收藏
      player: "", //播放器
      score: "",
      textarea: "",
      anonymous: false,
      hostLink:'',
      websiteData:''
    };
  },
  components: {
    Star,
  },
  mounted() {
    this.hostLink = window.location.host=='localhost:8081'?'kskweb.kskstudy.com':window.location.host
    this.websiteData = JSON.parse(localStorage.getItem('websiteState'))
    this.getCourseInfo(); //获取详情
    this.tabOpe(2)

  },
  methods: {
    ...mapMutations(["ShowLoginModal", "CloseLoginModal", "ShowTipModal"]), //登录弹窗
    //获取详情
    getCourseInfo() {
      let data = {
        subject_id: this.$route.params.id,
        host:this.hostLink
      };

      if (localStorage.getItem("user")) {
        data.user_id = JSON.parse(localStorage.getItem("user")).id;
      }

      this.$axios.post(`/v1/subject/detail`, data).then((res) => {
        res.data.desc = res.data.desc.replace(/<img/gi, '<img style="max-width:100%;height:auto;"');

        this.courseInfo = res.data;
        this.isCollect = res.data.is_collect;

        this.getEvaluation(); //获取评价
      });
    },
    //提交评价
    submitBtn() {
      let userInfo = localStorage.getItem("user");

      if (userInfo) {
        if (this.score == 0) {
          this.ShowTipModal({
            text: "请先打分1", //提示弹窗内容
          });

          return;
        }

        if (this.textarea == "") {
          this.ShowTipModal({
            text: "请填写评价内容", //提示弹窗内容
          });

          return;
        }

        this.$axios
          .post(
            `/v1/subject/evaluation`,
            {
              subject_id: this.$route.params.id,
              is_anonymous: this.anonymous ? 1 : 0,
              score: this.score,
              content: this.textarea,
              host:this.hostLink
            },
            { useLog: true }
          )
          .then((res) => {
            if (res.code == 0) {
              let _this = this;
              this.ShowTipModal({
                text: "评论成功", //提示弹窗内容
              });

              setTimeout(function () {
                _this.getEvaluation();
              }, 2000);
            } else {
              this.ShowTipModal({
                text: res.msg, //提示弹窗内容
              });
            }
          });
      } else {
        this.ShowLoginModal();
      }
    },
    //tab切换
    tabOpe(index) {
      this.tabIndex = index;
      if (index == 2 && this.chapterSection == "") {
        //获取课程章节列表
        this.$axios
          .post(`/v1/subject/detail/courseList`, {
            subject_id: this.$route.params.id,
          })
          .then((res) => {
            this.chapterSection = res.data;
            // 保存第一个课程的ID
            if(res.data && res.data.length > 0) {
              this.firstCourseId = res.data[0].id;
            }
          });
      }
    },
    //获取评价
    getEvaluation() {
      //获取评论
      this.$axios
        .post(`/v1/subject/detail/evaluationList`, {
          subject_id: this.$route.params.id,
          page: 1,
          pre_page: 10,
        })
        .then((res) => {
          this.evaluation = res.data;
        });
    },
    //立即购买
    payNowBtn(id) {
      //判断登录
      let userInfo = localStorage.getItem("user");

      if (userInfo) {
        this.$router.push({ path: "/confirmOrder/series_" + id });
      } else {
        this.ShowLoginModal();
      }
    },
    //收藏
    collectBtn(id) {
      let userInfo = localStorage.getItem("user");

      if (userInfo) {
        this.$axios
          .post(`/v1/subject/detail/collect`, { id }, { useLog: true })
          .then((res) => {
            if (res) {
              this.isCollect = this.isCollect == 1 ? 0 : 1;
            }
          });
      } else {
        this.ShowLoginModal();
      }
    },
    //获取子组件分数
    getScore(data) {
      this.score = data;
    },
    //试看
    tryView() {
      this.$axios
        .post(`/v1/subject/detail/testPlay`, {
          subject_id: this.$route.params.id,
        })
        .then((res) => {
          let _this = this;
          this.videoModal = true;
          this.player = new Aliplayer(
            {
              id: "video-box",
              width: "100%",
              height: "100%",
              source: res.data.info ? res.data.info.original_link : "", //视频地址
              autoplay: false,
              showBarTime: 1000,
              skinLayout: [
                { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "tlabs", x: 0, y: 0 }, //隐藏进度条
                    { name: "playButton", align: "tl", x: 15, y: 13 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 6 },
                    { name: "fullScreenButton", align: "tr", x: 20, y: 12.5 },
                    { name: "volume", align: "tr", x: 20, y: 12.5 },
                  ],
                },
              ],
            },
            function (player) {
              player.setPreviewTime(3 * 60); //试看30秒

              //暂停播放
              player.on("pause", function () {
                if (player.getCurrentTime() >= 180) {
                  _this.ShowTipModal({
                    text: "您只能试看3分钟", //提示弹窗内容
                  });
                }
              });
            }
          );
        });
    },
    //关闭视频弹窗
    closeVideoModal() {
      if (this.player) {
        this.videoModal = false;
        this.player.dispose();
      }
    },
  },
  // 页面销毁
  destroyed() {
    this.player.dispose();
  },
};
</script>
<style scoped lang="scss">
.recorded-info {
  background-color: #f7f7f7;
}
.banner {
  width: 100%;
  height: 376px;
  background: url(~@/assets/top-bg.png) no-repeat center center;
  background-size: auto 100%;
}
.banner-content {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.banner-info {
  width: 675px;
  padding-top: 72px;
  float: left;
}
.banner-title {
  font-size: 24px;
  font-weight: 800;
  color: #fff;
  margin-bottom: 12px;
}
.banner-tip {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  .line {
    margin: 0 10px;
  }
}
.banner-describe {
  max-height: 56px;
  font-size: 16px;
  color: #fff;
  line-height: 28px;
  //多行省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 10px;
}
.grade {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  .grade-statistic {
    font-size: 14px;
    color: #f7f7f7;
  }
}
.marks {
  .mark {
    padding: 7px 11px;
    background-color: #fff;
    border-radius: 18px 4px 18px 4px;
    font-size: 14px;
    color: #254ed4;
  }
}
.recorded-content {
  width: 1200px;
  margin: 0 auto;
  padding: 34px 0 88px;
}
.tab-box {
  width: 714px;
  background: #fff;
  border-radius: 1px;
  float: left;
  .tab-titles {
    box-sizing: border-box;
    width: 100%;
    padding-left: 24px;
    display: flex;
    box-shadow: 0px 1px 0px 0px rgba(37, 78, 212, 0.3);
  }
  .tab-title {
    padding: 24px 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 16px;
    color: #666;
    margin-right: 64px;
    position: relative;
    cursor: pointer;
    &.active::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background: #254ed4;
      border-radius: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .try {
      width: 34px;
      height: 16px;
      line-height: 16px;
      background-color: #e0e6f9;
      color: #254ed4;
      font-size: 12px;
      text-align: center;
      margin-left: 2px;
    }
  }
  .tab-content {
    box-sizing: border-box;
    width: 100%;
    min-height: 625px;
    padding: 30px 24px;
  }
}
.describe {
  font-size: 14px;
  color: #999;
  line-height: 26px;
}
.directory {
  width: 100%;
  .chapter {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    &:hover {
      box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
      border-radius: 6px;
      cursor: pointer;
    }
  }
  .chapter-img {
    width: 145px;
    height: 80px;
    border-radius: 6px;
  }
  .chapter-content {
    width: 375px;
    height: 80px;
    margin-left: 15px;
  }
  .chapter-name {
    font-size: 16px;
    font-weight: 800;
    color: #000c28;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .teacher {
    padding-top: 17px;
  }
  .chapter-price {
    text-align: right;
    margin-left: auto;
    .price {
      font-size: 16px;
      font-weight: 900;
      color: #cd1b14;
    }
    .original-pric {
      font-size: 12px;
      color: #999;
    }
  }
}
.evaluate-list {
  padding: 20px 24px;
}
.evaluate-info {
  padding: 24px 0;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  .user-img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    float: left;
    margin-right: 20px;
  }
  .evaluate-content {
    width: 590px;
    float: left;
    ::v-deep .grade {
      .star {
        width: 17px;
        height: 17px;
        margin-right: 8px;
      }
    }
  }
  .evaluate-title {
    padding-top: 10px;
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
    .date {
      font-size: 12px;
      color: #999999;
      float: right;
    }
  }
  .grade {
    margin-bottom: 20px;
    .grade-statistic {
      font-size: 14px;
      color: #666;
    }
  }
  .evaluate-text {
    font-size: 16px;
    color: #333;
    line-height: 18px;
  }
}
.selling-info {
  width: 460px;
  float: right;
}
.selling-introduce {
  box-sizing: border-box;
  width: 460px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 12, 40, 0.14);
  border-radius: 9px;
  border: 2px solid #fff;
  margin-top: -309px;
  margin-bottom: 30px;
  position: relative;
  .collection {
    height: 20px;
    padding-left: 30px;
    background: url(~@/assets/star-air-ico.png) no-repeat left center;
    background-size: auto 100%;
    line-height: 20px;
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: -30px;
    right: 0;
    cursor: pointer;
    &.active {
      background: url(~@/assets/star-white-ico.png) no-repeat left center;
      background-size: auto 100%;
    }
  }
}
.selling-img {
  width: 456px;
  height: 273px;
  margin-bottom: 22px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.selling-content {
  padding: 0 64px 34px 34px;
  .price {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
    .current-price {
      font-size: 27px;
      font-weight: 900;
      color: #cd1b14;
    }
    .original-cost {
      font-size: 14px;
      color: #999;
      margin-left: 14px;
      text-decoration: line-through;
    }
  }
  .purchased {
    font-size: 16px;
    color: #999;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    padding-top: 44px;
    .btn {
      width: 114px;
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
      border: 1px solid #d8d8d8;
      font-size: 16px;
      color: #999;
      text-align: center;
      cursor: pointer;
      &.test {
        background-color: #254ed4;
        border-color: #254ed4;
        color: #fff;
        text-align: center;
      }
      &.goPay {
        background: #e15416;
        border-color: #e15416;
        color: #fff;
      }
    }
  }
}
.view-user {
  box-sizing: border-box;
  width: 100%;
  padding: 34px;
  background-color: #fff;
  border-radius: 1px;
}
.view-user-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 32px;
}
.view-user-list {
  width: 414px;
  margin-left: -13px;
  overflow: hidden;
}
.view-user-img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  float: left;
  margin: 0 13px 25px 13px;
  box-shadow: 0px 0px 12px 0px rgba(0, 12, 40, 0.14);
  border: 1px solid #ffffff;
}
.video-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    width: 600px;
    height: 400px;
    background-color: #fff;
  }
  .modal-title {
    padding: 10px 30px;
    border-bottom: 1px solid #333;
    position: relative;
    .modal-close {
      width: 30px;
      height: 30px;
      background: url(~@/assets/close-ico.png) no-repeat left center;
      background-size: auto 100%;
      position: absolute;
      top: 0;
      right: -40px;
      cursor: pointer;
    }
  }
}
.evaluate {
  box-sizing: border-box;
  width: 100%;
  padding: 28px 34px;
  margin: 0 auto;
  background-color: #fff;
  .stars {
    margin-bottom: 15px;
  }
  .evaluate-text {
    box-sizing: border-box;
    width: 100%;
    height: 178px;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 6px;
    margin-bottom: 24px;
  }
  .evaluate-ope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 58px;
    .evaluate-ope {
      font-size: 16px;
      color: #ffffff;
    }
    .submit-btn {
      width: 114px;
      height: 40px;
      background: #254ed4;
      border-radius: 6px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }
    .anonymity {
      cursor: pointer;
    }
  }
}
</style>